import React from "react"
import SEO from "../components/seo"
import { graphql, Link, useStaticQuery } from 'gatsby';
import Layout from "../layouts/layout";



const NotFoundPage = () => {

   const jumpstart = useStaticQuery(graphql`
        query notFoundbackgroundImage {
            background: contentfulAsset(contentful_id: {eq: "3BgDUoJU5dGnOYaJg6M0SP"}) {
                 resize (width: 1440, quality: 70) {
                    src 
                }
           }
        }
    `)

  const hero = {}; 
  hero.heroBackgroundImage = jumpstart.background;



  return (
  <>
    <SEO title="404 Not found" />
        <Layout hero={hero}>
            <div className="container mx-auto text-center mb-16 sm:mb-32 px-4">
                 <p className="text-lg">Sorry, the page you requested could not be found.</p>
                 <p><Link className="inline-block uppercase pt-6 border-b-2 pb-1 text-xs border-mane-yellow text-gray-800 font-bold" to="/">
                    Visit Homepage</Link></p>
           </div>
        </Layout>
  </>
)};

export default NotFoundPage
